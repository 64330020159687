import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ColorsCard from "../../../components/CardColor/CardColor.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Usage`}</h2>
    <p>{`Colors system leverages a purposeful set of color styles as the perfect starting point for any brand or project.
When it comes to color, contrast is critical for ensuring text is legible.`}</p>
    <h3>{`Usage Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Color.LGNTheme.<colorCode>
`}</code></pre>
    <h3>{`Usage Theme`}</h3>
    <p>{`ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNColor.<colorCode>
`}</code></pre>
    <div className="divi" />
    <h2>{`Primary Colors`}</h2>
    <p>{`The primary color is your “brand” color, and is used across all interactive elements such as buttons, links,
inputs, etc. This color can define the overall feel and can elicit emotion.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="primary25" mdxType="ColorsCard" />
  <ColorsCard variant="primary50" mdxType="ColorsCard" />
  <ColorsCard variant="primary100" mdxType="ColorsCard" />
  <ColorsCard variant="primary200" mdxType="ColorsCard" />
  <ColorsCard variant="primary300" mdxType="ColorsCard" />
  <ColorsCard variant="primary400" mdxType="ColorsCard" />
  <ColorsCard variant="primary500" mdxType="ColorsCard" />
  <ColorsCard variant="primary600" mdxType="ColorsCard" />
  <ColorsCard variant="primary700" mdxType="ColorsCard" />
  <ColorsCard variant="primary800" mdxType="ColorsCard" />
  <ColorsCard variant="primary900" mdxType="ColorsCard" />
    </div>
    <h3>{`Primary Colors Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Color.LGNTheme.primary
Color.LGNTheme.primary25
Color.LGNTheme.primary50
Color.LGNTheme.primary100
Color.LGNTheme.primary200
Color.LGNTheme.primary300
Color.LGNTheme.primary400
Color.LGNTheme.primary500
Color.LGNTheme.primary600
Color.LGNTheme.primary700
Color.LGNTheme.primary800
Color.LGNTheme.primary900
`}</code></pre>
    <h3>{`Primary Colors Theme`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNColor.primary
LGNColor.primary25
LGNColor.primary50
LGNColor.primary100
LGNColor.primary200
LGNColor.primary300
LGNColor.primary400
LGNColor.primary500
LGNColor.primary600
LGNColor.primary700
LGNColor.primary800
LGNColor.primary900
`}</code></pre>
    <br />
    <br />
    <h2>{`Secondary Colors`}</h2>
    <p>{`The secondary colors is color highlight or complement the primary color.
These are to be used sparingly to make the UI elements stand out. These colors are also usually defined in the brand guidelines.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="secondary25" mdxType="ColorsCard" />
  <ColorsCard variant="secondary50" mdxType="ColorsCard" />
  <ColorsCard variant="secondary100" mdxType="ColorsCard" />
  <ColorsCard variant="secondary200" mdxType="ColorsCard" />
  <ColorsCard variant="secondary300" mdxType="ColorsCard" />
  <ColorsCard variant="secondary400" mdxType="ColorsCard" />
  <ColorsCard variant="secondary500" mdxType="ColorsCard" />
  <ColorsCard variant="secondary600" mdxType="ColorsCard" />
  <ColorsCard variant="secondary700" mdxType="ColorsCard" />
  <ColorsCard variant="secondary800" mdxType="ColorsCard" />
  <ColorsCard variant="secondary900" mdxType="ColorsCard" />
    </div>
    <h3>{`Secondary Colors Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Color.LGNTheme.secondary25
Color.LGNTheme.secondary50
Color.LGNTheme.secondary100
Color.LGNTheme.secondary200
Color.LGNTheme.secondary300
Color.LGNTheme.secondary400
Color.LGNTheme.secondary500
Color.LGNTheme.secondary600
Color.LGNTheme.secondary700
Color.LGNTheme.secondary800
Color.LGNTheme.secondary900
`}</code></pre>
    <h3>{`Secondary Colors Theme`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNColor.secondary25
LGNColor.secondary50
LGNColor.secondary100
LGNColor.secondary200
LGNColor.secondary300
LGNColor.secondary400
LGNColor.secondary500
LGNColor.secondary600
LGNColor.secondary700
LGNColor.secondary800
LGNColor.secondary900
`}</code></pre>
    <br />
    <br />
    <h2>{`Tertiary Colors`}</h2>
    <p>{`The tertiary color is a neutral color and is the foundation of the color system.
Almost everything in UI design — text, form fields, backgrounds, dividers, outline card — are usually gray.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="tertiary25" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary50" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary100" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary200" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary300" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary400" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary500" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary600" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary700" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary800" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary900" mdxType="ColorsCard" />
    </div>
    <h3>{`Tertiary Colors Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Color.LGNTheme.tertiary25
Color.LGNTheme.tertiary50
Color.LGNTheme.tertiary100
Color.LGNTheme.tertiary200
Color.LGNTheme.tertiary300
Color.LGNTheme.tertiary400
Color.LGNTheme.tertiary500
Color.LGNTheme.tertiary600
Color.LGNTheme.tertiary700
Color.LGNTheme.tertiary800
Color.LGNTheme.tertiary900
`}</code></pre>
    <h3>{`Tertiary Colors Theme`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNColor.tertiary25
LGNColor.tertiary50
LGNColor.tertiary100
LGNColor.tertiary200
LGNColor.tertiary300
LGNColor.tertiary400
LGNColor.tertiary500
LGNColor.tertiary600
LGNColor.tertiary700
LGNColor.tertiary800
LGNColor.tertiary900
`}</code></pre>
    <br />
    <br />
    <h2>{`Semantic Colors`}</h2>
    <p>{`Semantic colours communicate important status to the user such as success, information,
warning or error. These colours should be used paired with text, an icon or both to
fulfill their purpose in the best way possible.`}</p>
    <h2>{`Success`}</h2>
    <p>{`Success colors communicate a positive action, postive trend, or a successful confirmation.
If you’re using green as your primary color, it can be helpful to introduce a different
hue for your success green.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="success25" mdxType="ColorsCard" />
  <ColorsCard variant="success50" mdxType="ColorsCard" />
  <ColorsCard variant="success100" mdxType="ColorsCard" />
  <ColorsCard variant="success200" mdxType="ColorsCard" />
  <ColorsCard variant="success300" mdxType="ColorsCard" />
  <ColorsCard variant="success400" mdxType="ColorsCard" />
  <ColorsCard variant="success500" mdxType="ColorsCard" />
  <ColorsCard variant="success600" mdxType="ColorsCard" />
  <ColorsCard variant="success700" mdxType="ColorsCard" />
  <ColorsCard variant="success800" mdxType="ColorsCard" />
  <ColorsCard variant="success900" mdxType="ColorsCard" />
    </div>
    <h3>{`Success Colors Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Color.LGNTheme.success25
Color.LGNTheme.success50
Color.LGNTheme.success100
Color.LGNTheme.success200
Color.LGNTheme.success300
Color.LGNTheme.success400
Color.LGNTheme.success500
Color.LGNTheme.success600
Color.LGNTheme.success700
Color.LGNTheme.success800
Color.LGNTheme.success900
`}</code></pre>
    <h3>{`Success Colors Theme`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNColor.success25
LGNColor.success50
LGNColor.success100
LGNColor.success200
LGNColor.success300
LGNColor.success400
LGNColor.success500
LGNColor.success600
LGNColor.success700
LGNColor.success800
LGNColor.success900
`}</code></pre>
    <br />
    <br />
    <h3>{`Warning`}</h3>
    <p>{`Warning colors can communicate that an action is potentially destructive or “on-hold”.
These colors are commonly used in confirmations to grab the users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="warning25" mdxType="ColorsCard" />
  <ColorsCard variant="warning50" mdxType="ColorsCard" />
  <ColorsCard variant="warning100" mdxType="ColorsCard" />
  <ColorsCard variant="warning200" mdxType="ColorsCard" />
  <ColorsCard variant="warning300" mdxType="ColorsCard" />
  <ColorsCard variant="warning400" mdxType="ColorsCard" />
  <ColorsCard variant="warning500" mdxType="ColorsCard" />
  <ColorsCard variant="warning600" mdxType="ColorsCard" />
  <ColorsCard variant="warning700" mdxType="ColorsCard" />
  <ColorsCard variant="warning800" mdxType="ColorsCard" />
  <ColorsCard variant="warning900" mdxType="ColorsCard" />
    </div>
    <h3>{`Warning Colors Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Color.LGNTheme.warning25
Color.LGNTheme.warning50
Color.LGNTheme.warning100
Color.LGNTheme.warning200
Color.LGNTheme.warning300
Color.LGNTheme.warning400
Color.LGNTheme.warning500
Color.LGNTheme.warning600
Color.LGNTheme.warning700
Color.LGNTheme.warning800
Color.LGNTheme.warning900
`}</code></pre>
    <h3>{`Warning Colors Theme`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNColor.warning25
LGNColor.warning50
LGNColor.warning100
LGNColor.warning200
LGNColor.warning300
LGNColor.warning400
LGNColor.warning500
LGNColor.warning600
LGNColor.warning700
LGNColor.warning800
LGNColor.warning900
`}</code></pre>
    <br />
    <br />
    <h3>{`Information`}</h3>
    <p>{`Infomation colors can communicate that an action is informative section or`}<br parentName="p"></br>{`
`}{`these colors are commonly used in awareness for users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="information25" mdxType="ColorsCard" />
  <ColorsCard variant="information50" mdxType="ColorsCard" />
  <ColorsCard variant="information100" mdxType="ColorsCard" />
  <ColorsCard variant="information200" mdxType="ColorsCard" />
  <ColorsCard variant="information300" mdxType="ColorsCard" />
  <ColorsCard variant="information400" mdxType="ColorsCard" />
  <ColorsCard variant="information500" mdxType="ColorsCard" />
  <ColorsCard variant="information600" mdxType="ColorsCard" />
  <ColorsCard variant="information700" mdxType="ColorsCard" />
  <ColorsCard variant="information800" mdxType="ColorsCard" />
  <ColorsCard variant="information900" mdxType="ColorsCard" />
    </div>
    <h3>{`Information Colors Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Color.LGNTheme.information25
Color.LGNTheme.information50
Color.LGNTheme.information100
Color.LGNTheme.information200
Color.LGNTheme.information300
Color.LGNTheme.information400
Color.LGNTheme.information500
Color.LGNTheme.information600
Color.LGNTheme.information700
Color.LGNTheme.information800
Color.LGNTheme.information900
`}</code></pre>
    <h3>{`Information Colors Theme`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNColor.information25
LGNColor.information50
LGNColor.information100
LGNColor.information200
LGNColor.information300
LGNColor.information400
LGNColor.information500
LGNColor.information600
LGNColor.information700
LGNColor.information800
LGNColor.information900
`}</code></pre>
    <br />
    <br />
    <h3>{`Error`}</h3>
    <p>{`Error colors are used across error states and in “destructive” actions.
They communicate a destructive/negative action, such as removing a user from your team.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="error25" mdxType="ColorsCard" />
  <ColorsCard variant="error50" mdxType="ColorsCard" />
  <ColorsCard variant="error100" mdxType="ColorsCard" />
  <ColorsCard variant="error200" mdxType="ColorsCard" />
  <ColorsCard variant="error300" mdxType="ColorsCard" />
  <ColorsCard variant="error400" mdxType="ColorsCard" />
  <ColorsCard variant="error500" mdxType="ColorsCard" />
  <ColorsCard variant="error600" mdxType="ColorsCard" />
  <ColorsCard variant="error700" mdxType="ColorsCard" />
  <ColorsCard variant="error800" mdxType="ColorsCard" />
  <ColorsCard variant="error900" mdxType="ColorsCard" />
    </div>
    <h3>{`Error Colors Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Color.LGNTheme.error25
Color.LGNTheme.error50
Color.LGNTheme.error100
Color.LGNTheme.error200
Color.LGNTheme.error300
Color.LGNTheme.error400
Color.LGNTheme.error500
Color.LGNTheme.error600
Color.LGNTheme.error700
Color.LGNTheme.error800
Color.LGNTheme.error900
`}</code></pre>
    <h3>{`Error Colors Theme`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`LGNColor.error25
LGNColor.error50
LGNColor.error100
LGNColor.error200
LGNColor.error300
LGNColor.error400
LGNColor.error500
LGNColor.error600
LGNColor.error700
LGNColor.error800
LGNColor.error900
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      